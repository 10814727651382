/**
 * Signin Firebase
 */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import { Fab } from "@material-ui/core";
import baseURL from "../baseurl";
import Footer from "Components/Footer/Footer";

import {
  apiAction,
  toastAction,
  toastActionAlert,
  userAction,
} from "../customRedux/actions/Actions";
import {
  toastConstant,
  apiConstant,
  userConstant,
  HRuserConstant,
} from "../customRedux/constants/actionConstant";
import { apiPost, apiFormDatePost, setLoggedInuser } from "../Api/apiCommon";
import { useHistory } from "react-router-dom";
import image from "../Assets/img/celio.png";
// components
import { SessionSlider } from "../Components/Widgets/SessionSlider";

// app config
import AppConfig from "Constants/AppConfig";

// redux action
import {
  signinUserInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
} from "Store/Actions";

// //Auth File
// import Auth from "Auth/Auth";

// const auth = new Auth();

const AppSignIn = () => {
  //   const [email, setEmail] = useState("demo@example.com");
  //   const [password, setPassword] = useState("test#123");
  //   const dispatch = useDispatch();
  //   const loading = useSelector((state) => state.loading);

  //   /**
  //    * On User Login
  //    */
  //   const onUserLogin = () => {
  //     if (email !== "" && password !== "") {
  //       dispatch(signinUserInFirebase({ email, password }, props.history));
  //     }
  //   };

  //   /**
  //    * On User Sign Up
  //    */
  //   const onUserSignUp = () => {
  //     props.history.push("/signup");
  //   };

  //   //Auth0 Login
  //   const loginAuth0 = () => {
  //     auth.login();
  //   };

  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOTPSend, setIsOTPSend] = useState(false);
  const [sendOTPbtn, setSendOTPbtn] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [isMsgShow, setIsMsgShow] = useState(false);
  const [MsgType, setMsgType] = useState("alert alert-success");

  //validation variables
  const [emailMssage, setEmailMssage] = useState("");
  const [emailErrorVisible, setEmailErrorVisible] = useState("none");
  const [otpMssage, setOTPEmailMssage] = useState("");
  const [otpErrorVisible, setOTPEmailErrorVisible] = useState("none");
  const [btnLoader, setbtnLoader] = useState(false);
  const sendOTP = async () => {
    const tempEmail = email.trim();
    if (tempEmail != "") {
      try {
        setSendOTPbtn(true);
        const response = await apiPost("sendotp", {
          email: tempEmail,
        });
        if (!response.data.error) {
          setMsgType("alert alert-success");
          setMessage(response.data[0].STATUS);
          setIsMsgShow(true);
          setIsOTPSend(true);
        } else {
          setSendOTPbtn(false);
          setMsgType("alert alert-danger");
          setMessage(response.data.errorMessage);
          setIsMsgShow(true);
        }
      } catch (error) {
        setSendOTPbtn(false);
        dispatch(toastAction(toastConstant.setToast, "Internal Server Error"));
        dispatch(toastAction(toastConstant.errorToast));
        console.log(error);
      }
    } else {
      setEmailMssage("Please Enter Email");
      setEmailErrorVisible("block");
    }
  };

  const d = new Date();
  let year = d.getFullYear();

  const Login = async (event) => {
    event.preventDefault();

    const tEmail = email.trim();
    const tempOTP = OTP.trim();

    try {
      setbtnLoader(true);

      const response = await apiPost("login", {
        email: tEmail,
        password: tempOTP,
      });

      if (!response.data.error) {
        console.log(response);
        console.log(response.data.userDetails);
        // dispatch(toastAction(toastConstant.setToast, response.data.message));
        // dispatch(toastAction(toastConstant.successToast));
        setLoggedInuser(response.data.userDetails, response.data.token);
        if (response.data.userDetails.roleUID == 1) {
          // dispatch(
          //   userAction(userConstant.setAdminUser, response.data.userDetails)
          // );
          setTimeout(() => history.push("/corporate/admin/dashboard"), 1000);
        } else if (response.data.userDetails.roleUID == 2) {
          // dispatch(
          //   userAction(HRuserConstant.setHRUser, response.data.userDetails)
          // );
          setTimeout(() => history.push("/corporate/manager/dashboard"), 1000);
        }
      } else {
        setMsgType("alert alert-danger");
        setMessage(response.data.errorMessage);
        setIsMsgShow(true);
        setbtnLoader(false);
      }
    } catch (error) {
      // dispatch(toastAction(toastConstant.setToast, "Internal Server Error"));
      // dispatch(toastAction(toastConstant.errorToast));
      console.log(error);
      setbtnLoader(false);
    }
  };

  const resetError = (event) => {
    setEmailMssage("");
    setEmailErrorVisible("none");
  };

  const login = (event) => {
    event.preventDefault();
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {/* {loading && <LinearProgress />} */}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/signin">
                    <img
                      src={image}
                      alt="session-logo"
                      className="img-fluid"
                      width="110"
                      height="35"
                    />
                  </Link>
                </div>
                {/* <div>
                  <a
                    href="!#"
                    className="mr-15 text-white"
                    //   onClick={onUserSignUp}
                  >
                    Create New account?
                  </a>
                  <Button
                    variant="contained"
                    className="btn-light"
                    //   onClick={onUserSignUp}
                  >
                    Sign Up
                  </Button>
                </div> */}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-6 mx-auto">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <h2 className="font-weight-bold">
                      <a href="#" className="logo logo-admin">
                        <img src={image} height="60" alt="logo" />
                      </a>
                    </h2>
                    {/* <p className="mb-0">Most powerful ReactJS admin panel</p> */}
                  </div>
                  <div
                    className={MsgType}
                    id="successmsg"
                    style={{ display: isMsgShow ? "block" : "none" }}
                  >
                    {message}
                  </div>
                  <Form onSubmit={Login}>
                    <FormGroup className="has-wrapper">
                      <Input
                        id="email"
                        type="email"
                        className="form-control "
                        name="email"
                        value={email}
                        onKeyUp={resetError}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Your Email"
                        required
                      />
                      <span className="has-icon">
                        <i className="ti-email"></i>
                      </span>
                      <div
                        className="inputImportantRed"
                        id="emailprint-error-msg-red"
                        style={{ display: emailErrorVisible }}
                      >
                        {emailMssage}
                      </div>
                    </FormGroup>
                    <FormGroup className="has-wrapper">
                      <Input
                        id="otpvalue"
                        type="text"
                        className="form-control otpvalue"
                        value={OTP}
                        onChange={(e) => setOTP(e.target.value)}
                        name="otpvalue"
                        style={{ display: isOTPSend ? "block" : "none" }}
                        placeholder="Please Enter OTP"
                        required
                      />
                      <span className="has-icon">
                        <i className="ti-lock"></i>
                      </span>
                      <div
                        className="inputImportantRed"
                        id="emailprint-error-msg-red"
                        style={{ display: otpErrorVisible }}
                      >
                        {otpMssage}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-15">
                      <Button
                        color="primary"
                        className="btn-block text-white w-100"
                        variant="contained"
                        size="large"
                        type="button"
                        style={{ display: !isOTPSend ? "block" : "none" }}
                        onClick={sendOTP}
                        disabled={sendOTPbtn}
                      >
                        {sendOTPbtn && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {sendOTPbtn && <span>Sending OTP to Mail</span>}
                        {!sendOTPbtn && <span>Send OTP</span>}
                      </Button>
                    </FormGroup>
                    <FormGroup className="mb-15">
                      {/* <Button
                        variant="contained"
                        className="btn-info btn-block text-white w-100"
                        size="large"
                        type="submit"
                        style={{ display: isOTPSend ? "block" : "none" }}
                        disabled="disabled"
                      >
                        Login{" "}
                      </Button> */}
                      <button
                        variant="contained"
                        className="btn-info btn-block text-white w-100"
                        size="large"
                        type="submit"
                        style={{
                          display: isOTPSend ? "block" : "none",
                          fontSize: "1.4rem",
                        }}
                        disabled={btnLoader}
                      >
                        {btnLoader ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ) : null}
                        Login{" "}
                      </button>
                      {/* ) : (
                      <button
                        variant="contained"
                        className="btn-info btn-block text-white w-100"
                        size="large"
                        type="submit"
                        style={{ display: isOTPSend ? "block" : "none" }}
                      >
                        Login{" "}
                      </button>
                      )} */}
                    </FormGroup>
                  </Form>
                  {/* <p className="mb-20">or sign in with</p>
                  <Fab
                    size="small"
                    variant="circular"
                    className="btn-facebook mr-15 mb-20 text-white"
                    onClick={() =>
                      dispatch(signinUserWithFacebook(props.history))
                    }
                  >
                    <i className="zmdi zmdi-facebook"></i>
                  </Fab>
                  <Fab
                    size="small"
                    variant="circular"
                    className="btn-google mr-15 mb-20 text-white"
                    onClick={() =>
                      dispatch(signinUserWithGoogle(props.history))
                    }
                  >
                    <i className="zmdi zmdi-google"></i>
                  </Fab>
                  <Fab
                    size="small"
                    variant="circular"
                    className="btn-twitter mr-15 mb-20 text-white"
                    onClick={() =>
                      dispatch(signinUserWithTwitter(props.history))
                    }
                  >
                    <i className="zmdi zmdi-twitter"></i>
                  </Fab>
                  <Fab
                    size="small"
                    variant="circular"
                    className="btn-instagram mr-15 mb-20 text-white"
                    onClick={() =>
                      dispatch(signinUserWithGithub(props.history))
                    }
                  >
                    <i className="zmdi zmdi-github-alt"></i>
                  </Fab>
                  <p className="text-muted">
                    By signing up you agree to {AppConfig.brandName}
                  </p>
                  <p className="mb-0">
                    <a
                      target="_blank"
                      href="#/terms-condition"
                      className="text-muted"
                    >
                      Terms of Service
                    </a>
                  </p> */}
                </div>

                {/* <div className="col-sm-5 col-md-5 col-lg-4">
                <SessionSlider />
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div
            className="container-fluid"
            style={{
              marginTop: "20%",
              justifyContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            <div className="row">
              <div>
                Celio Internaluse by{" "}
                <a
                  href="https://www.bixware.com/"
                  target="_blank"
                  style={{ fontWeight: "bold" }}
                >
                  Bixware Technologies
                </a>{" "}
                © {year}. All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      </div>
    </QueueAnim>
  );
};

export default AppSignIn;
