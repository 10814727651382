/**
 * News Dashboard
 */

import React from 'react'
import { Helmet } from "react-helmet";
import { useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import { useHistory } from "react-router-dom";
// rct collapsible card
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

//Widgets
import {
	TrendingNews,
	TopHeadlines,
	Visitors,
	Subscribers,
	NewslaterCampaign,
	CommentsWidget,
	SocialFeedsWidget,
	TopAuthors,
	RecentActivity,
	TopNews,
	TwitterFeedsV2,
	Notifications
} from "Components/Widgets";
import {
   apiPost,
   apiFormDatePost,
   setLoggedInuser,
 } from "../../../Api/apiCommon";
import { Icon } from '@iconify/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";

export default function NewsDashboard(){

   const [isVisible,setIsVisible]=useState(true);

   const userString = sessionStorage.getItem("bixware_user");
   const User = JSON.parse(userString);
   console.log(User);
   const reduxState=useSelector(state=>state);
   const dispatch=useDispatch();
   const history = useHistory();
   useEffect(() => {
        getEmpCount();
        getBirthdayList();
        getonboardCount();
     }, []);

  const [empCountDetails,setempCountDetails]=useState({});
  const [onboardCountDetails,setonboardCountDetails]=useState({});
  const [empBirthDayDetails,setEmpBirthDayDetails]=useState([]);

  const getEmpCount =async ()=>
  {
   try{
            
       const response= await  apiPost('getdashboardempcount', { roleUID: User.roleUID,
         userUID: User.userUID});
       if(!response.data.error)
       {
           setempCountDetails(response.data.empCountDetails);
           console.log(response.data.empCountDetails);
           
       }
       else
       { 
           dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
           dispatch(toastAction(toastConstant.errorToast));
       }
      
    }
    catch(error) {
          dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
          dispatch(toastAction(toastConstant.errorToast));
          console.log(error);
   }
  }

  const getonboardCount =async ()=>
  {
   try{
            
       const response= await  apiPost('getdashboardonboardcount', { roleUID: User.roleUID,
         userUID: User.userUID});
       if(!response.data.error)
       {
           setonboardCountDetails(response.data.onboardCountDetails);
           console.log(response.data.onboardCountDetails);
       }
       else
       { 
           dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
           dispatch(toastAction(toastConstant.errorToast));
       }
      
    }
    catch(error) {
          dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
          dispatch(toastAction(toastConstant.errorToast));
          console.log(error);
   }
  }

  const getBirthdayList =async ()=>
  {
   try{
            
       const response= await  apiPost('getbirthdaylist', { roleUID: User.roleUID,
         userUID: User.userUID});
       if(!response.data.error)
       {
           setEmpBirthDayDetails(response.data.empBirthdaDetails);
           
       }
       else
       { 
           dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
           dispatch(toastAction(toastConstant.errorToast));
       }
      
    }
    catch(error) {
          dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
          dispatch(toastAction(toastConstant.errorToast));
          console.log(error);
   }
   setIsVisible(false);
  }
   return (
      <div className="news-dashboard-wrapper">
         <Helmet>
            <title>Dashboard</title>
            <meta name="description" content="Reactify News Dashboard" />
         </Helmet>
      
         {/* <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
                <SocialFeedsWidget>
                    
                           <div className="col-3 align-self-center">
                                 <div className="round">
                                 <i className="mdi mdi-account-multiple-plus"></i>
                                 </div>
                           </div>
                           <div className="col-9 align-self-center text-right">
                                 <div className="m-l-10">
                                    <h5 className="mt-0">{5}</h5>
                                    <p className="mb-0 text-muted">OnBoarding</p>
                                 </div>
                           </div>                                                                                          
                       
                  </SocialFeedsWidget>               
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
               <SocialFeedsWidget
                  type="twitter"
                  friendsCount="89k"
                  feedsCount="459"
                  icon="zmdi zmdi-twitter"
               />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
               <SocialFeedsWidget
                  type="linkedin"
                  friendsCount="89k"
                  feedsCount="459"
                  icon="zmdi zmdi-linkedin"
               />
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
               <SocialFeedsWidget
                  type="google"
                  friendsCount="89k"
                  feedsCount="459"
                  icon="zmdi zmdi-google"
               />
            </div>
         </div> */}
        <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="col-3 align-self-center">
                                    <div className="round" style={{ height: "20px" }}>
                                       {/* <i className="mdi mdi-account-multiple-plus"></i> */}
                                       <Link to={"/corporate/admin/newly"}>
                                       <FontAwesomeIcon icon={faUsers} />
                                       </Link>
                                    </div>
                                    </div>
                                    <div className="col-9 align-self-center text-right">
                                        <div className="m-l-10">
                                            <h5 className="mt-0">{empCountDetails.Total}</h5>
                                            <p className="mb-0 text-muted">NewEmployment</p>
                                        </div>
                                    </div>                                                                                          
                                </div>  
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="col-3 align-self-center">
                                    <div className="round">
                                       {/* <i className="mdi mdi-account-multiple-plus"></i> */}
                                       <Link to={"/corporate/admin/onboard"}>
                                       <FontAwesomeIcon icon={faUsers} />
                                       </Link>
                                    </div>
                                    </div>
                                    <div className="col-9 align-self-center text-right">
                                        <div className="m-l-10">
                                            <h5 className="mt-0">{onboardCountDetails.TotalOB}</h5>
                                            <p className="mb-0 text-muted">OnBoarding</p>
                                        </div>
                                    </div>                                                                                          
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
<br></br>
    <div className="row">
            <div className="col-xl-12">
                <div className="row">
                    <div className="col-xl-12 align-self-center">
                        <div className="card">
                            <div className="card-body">
                            <div className="card-body new-user">
                                <h4 className="header-title mb-3 mt-0">Upcoming Birthday's</h4>
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                       <thead>
                                          <tr>
                                             <th className="border-top-0 center" style={{textAlign: 'center'}}>Employee Name</th>
                                             <th className="border-top-0" style={{textAlign: 'center'}}>Date of Birth</th>
                                             <th className="border-top-0" style={{textAlign: 'center'}}>Upcoming Birthday's</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          {empBirthDayDetails.map((e, key) => {
                                             return <tr  key={key}><td style={{textAlign: 'center'}}>{e.name}</td><td style={{textAlign: 'center'}}>{e.DOB}</td><td className="birthDay"style={{color:e.colorCode,textAlign: 'center'}}><b>{e.BIRTHDAY}</b></td></tr>;
                                          })}  


                                          {empBirthDayDetails.length==0 ? (
                                             <tr><td colSpan={4} style={{ textAlign:'center' }}>No Birthday List Found Upcoming 30 days</td></tr>
                                          ) : (""
                                          )}
                     
                                       </tbody>                                      
                                    </table>                                                
                                </div>
                            </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
   )
}
