/**
 * User Management Page
 */
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "@material-ui/core";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { NotificationManager } from "react-notifications";
import Avatar from "@material-ui/core/Avatar";
import { Form, FormGroup, Label, Input } from "reactstrap";
// api
import api from "Api";
import {
  apiPost,
  apiFormDatePost,
  setLoggedInuser,
} from "../../../Api/apiCommon";
// delete confirmation dialog
import DeleteConfirmationDialog from "Components/DeleteConfirmationDialog/DeleteConfirmationDialog";
// add new user form
import AddNewUserForm from "./AddNewUserForm";
// update user form
import UpdateUserForm from "./UpdateUserForm";
// page title bar
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
// intl messages
import IntlMessages from "Util/IntlMessages";
// rct card box
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
// rct section loader
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function NewRegProfile(props) {
  const deleteConfirmationDialog = useRef();
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [addNewUserDetail, setAddNewUserDetail] = useState({
    name: "",
    dateofBirth: "",
    contactNo: "",
    parentsContactNo: "",
    pan: "",
    aadhar: "",
    qualification: "",
    previousExperience: "",
    personalEmail: "",
    expectedJoinDate: "",
    address: "",
    state: "",
    pincode: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    func: "",
    latestEdu: "",
    passedOut: "",
    certificate: "",
    video: "",
    panImage: "",
    aadharFrontImage: "",
    aadharBackImage: "",
    previousOffer: "",
    previousExperienceDoc: "",
    resumeFile: "",
    payslip1: "",
    payslip2: "",
    payslip3: "",
  });
  const [openViewUserDialog, setOpenViewUserDialog] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(0);

  // const [data, setData] = useState([]);
  const userString = sessionStorage.getItem("bixware_user");
  const User = JSON.parse(userString);
  console.log(User);
  // useEffect(() => {
  //   api
  //     .get("userManagement.js")
  //     .then((response) => {
  //       setUsers(response.data);
  //     })
  //     .catch((error) => {
  //       // error hanlding
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiPost("admin/getnewregisterlist", {
          roleUID: User.roleUID,
          userUID: User.userUID,
        });
        {
        }

        setUsers(response.data.empDetails);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  /**
   * On Delete
   */
  const onDelete = (data) => {
    deleteConfirmationDialog.current.open();
    setSelectedUser(data);
  };

  /**
   * Delete User Permanently
   */
  const deleteUserPermanently = () => {
    let allUsers = users;
    let indexOfDeleteUser = allUsers.indexOf(selectedUser);
    allUsers.splice(indexOfDeleteUser, 1);
    deleteConfirmationDialog.current.close();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setUsers(allUsers);
      setSelectedUser(null);
      NotificationManager.success("User Deleted!");
    }, 2000);
  };

  /**
   * Open Add New User Modal
   */
  const opnAddNewUserModal = (e) => {
    e.preventDefault();
    setAddNewUserModal(true);
  };

  /**
   * On Reload
   */
  const onReload = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  /**
   * On Select User
   */
  const onSelectUser = (user) => {
    user.checked = !user.checked;
    let selectedUsers = 0;
    let allUsers = users.map((userData) => {
      if (userData.checked) {
        selectedUsers++;
      }
      if (userData.id === user.id) {
        if (userData.checked) {
          selectedUsers++;
        }
        return user;
      } else {
        return userData;
      }
    });
    setUsers(allUsers);
    setSelectedUser(selectedUsers);
  };

  /**
   * On Change Add New User Details
   */
  const onChangeAddNewUserDetails = (key, value) => {
    setAddNewUserDetail({
      ...addNewUserDetail,
      [key]: value,
    });
  };

  /**
   * Add New User
   */
  const addNewUser = () => {
    const { name, emailAddress } = addNewUserDetail;
    if (name !== "" && emailAddress !== "") {
      let allUsers = users;
      let newUser = {
        ...addNewUserDetail,
        id: new Date().getTime(),
      };
      allUsers.push(newUser);
      setAddNewUserModal(false);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setUsers(allUsers);
        NotificationManager.success("User Created!");
      }, 2000);
    }
  };

  /**
   * View User Detail Hanlder
   */
  const viewUserDetail = (data) => {
    setOpenViewUserDialog(true);
    setSelectedUser(data);
  };

  /**
   * On Edit User
   */
  const onEditUser = (user) => {
    setAddNewUserModal(true);
    setEditUser(user);
  };

  /**
   * On Add & Update User Modal Close
   */
  const onAddUpdateUserModalClose = () => {
    setAddNewUserModal(false);
    setEditUser(null);
  };

  const onViewUserModalClose = () => {
    setOpenViewUserDialog(false);
    setSelectedUser(null);
  };

  /**
   * On Update User Details
   */
  const onUpdateUserDetails = (key, value) => {
    setEditUser({
      ...editUser,
      [key]: value,
    });
  };

  /**
   * Update User
   */
  const updateUser = () => {
    let indexOfUpdateUser = "";
    let allUsers = users;
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (user.id === editUser.id) {
        indexOfUpdateUser = i;
      }
    }
    allUsers[indexOfUpdateUser] = editUser;
    setLoading(true);
    setEditUser(null);
    setAddNewUserModal(false);
    setTimeout(() => {
      setUsers(allUsers);
      setLoading(false);
      NotificationManager.success("User Updated!");
    }, 2000);
  };

  //Select All user
  const onSelectAllUser = (e) => {
    let selectAll = selectedUsers < users.length;
    if (selectAll) {
      let selectAllUsers = users.map((user) => {
        user.checked = true;
        return user;
      });
      setUsers(selectAllUsers);
      setSelectedUsers(selectAllUsers.length);
    } else {
      let unselectedUsers = users.map((user) => {
        user.checked = false;
        return user;
      });
      setSelectedUsers(0);
      setUsers(unselectedUsers);
    }
  };
  return (
    <div className="user-management">
      <Helmet>
        {/* <title>Reactify | Users Management</title>
        <meta name="description" content="Reactify Widgets" /> */}
      </Helmet>
      {/* <PageTitleBar
        title={<IntlMessages id="sidebar.userManagement" />}
        match={props.match}
      /> */}
      <RctCollapsibleCard fullBlock>
        <div className="table-responsive">
          <div className="d-flex justify-content-between py-20 px-10 border-bottom">
            <div>
              <h4 className="mt-0 header-title">List Of New Registrations</h4>
              {/* <a
                href="!#"
                onClick={(e) => onReload(e)}
                className="btn-outline-default mr-10"
              >
                <i className="ti-reload"></i>
              </a> */}
              {/* <a
                href="!#"
                onClick={(e) => e.preventDefault()}
                className="btn-outline-default mr-10"
              >
                
              </a> */}
            </div>
            <div>
              {/* <a
                href="!#"
                onClick={(e) => e.preventDefault()}
                className="btn-sm btn-outline-default mr-10"
              >
                Export to Excel
              </a> */}
              {/* <a
                href="!#"
                onClick={(e) => opnAddNewUserModal(e)}
                color="primary"
                className="caret btn-sm mr-10"
              >
                Add New onboarding <i className="zmdi zmdi-plus"></i>
              </a> */}
              <NavLink to={"/corporate/admin/newregistration"}>
                <Button
                  // onClick={(e) => opnAddNewUserModal(e)}
                  variant="contained"
                  className="upgrade-btn tour-step-4 text-white"
                  color="primary"
                >
                  New Registration
                </Button>
              </NavLink>
            </div>
          </div>
          <table className="table table-middle table-hover mb-0">
            <thead>
              <tr>
                {/* <th className="w-5">
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={
                          selectedUsers > 0 && selectedUsers < users.length
                        }
                        checked={selectedUsers > 0}
                        onChange={(e) => onSelectAllUser(e)}
                        value="all"
                        color="primary"
                      />
                    }
                    label="All"
                  />
                </th> */}
                <th>Name</th>
                <th>Contact Number</th>
                <th>Email</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, key) => (
                  <tr key={key}>
                    {/* <td>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={user.checked}
                            onChange={() => onSelectUser(user)}
                            color="primary"
                          />
                        }
                      />
                    </td> */}
                    <td>{user.name}</td>
                    <td>{user.contactNo}</td>
                    <td>{user.personalEmail}</td>
                    <td>{user.dateofBirth}</td>
                    <td>{user.address}</td>
                    <td className="list-action">
                      <button
                        type="button"
                        className="rct-link-btn"
                        onClick={() => viewUserDetail(user)}
                      >
                        <i className="ti-eye"></i>
                      </button>
                      {/* <button
                        type="button"
                        className="rct-link-btn"
                        onClick={() => onEditUser(user)}
                      >
                        <i className="ti-pencil"></i>
                      </button> */}
                      <button
                        type="button"
                        className="rct-link-btn"
                        onClick={() => onDelete(user)}
                      >
                        <i className="ti-close"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* <tfoot className="border-top">
              <tr>
                <td colSpan="100%">
                  <Pagination className="mb-0 py-10 px-10">
                    <PaginationItem>
                      <PaginationLink
                        previous
                        href="!#"
                        onClick={(e) => e.preventDefault()}
                      />
                    </PaginationItem>
                    <PaginationItem active>
                      <PaginationLink
                        href="!#"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="!#"
                        onClick={(e) => e.preventDefault()}
                      >
                        2
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="!#"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        next
                        href="!#"
                        onClick={(e) => e.preventDefault()}
                      />
                    </PaginationItem>
                  </Pagination>
                </td>
              </tr>
            </tfoot> */}
          </table>
        </div>
        {loading && <RctSectionLoader />}
      </RctCollapsibleCard>
      <DeleteConfirmationDialog
        ref={deleteConfirmationDialog}
        title="Are You Sure Want To Delete?"
        message="This will delete user permanently."
        onConfirm={() => deleteUserPermanently()}
      />
      <Modal
        isOpen={addNewUserModal}
        toggle={() => onAddUpdateUserModalClose()}
      >
        <ModalHeader toggle={() => onAddUpdateUserModalClose()}>
          {editUser === null ? "Add New User" : "Update User"}
        </ModalHeader>
        <ModalBody>
          {editUser === null ? (
            <AddNewUserForm
              addNewUserDetails={addNewUserDetail}
              onChangeAddNewUserDetails={onChangeAddNewUserDetails}
            />
          ) : (
            <UpdateUserForm
              user={editUser}
              onUpdateUserDetail={onUpdateUserDetails}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {editUser === null ? (
            <Button
              variant="contained"
              className="text-white btn-success"
              onClick={() => addNewUser()}
            >
              Add
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="text-white"
              onClick={() => updateUser()}
            >
              Update
            </Button>
          )}{" "}
          <Button
            variant="contained"
            className="text-white btn-danger"
            onClick={() => onAddUpdateUserModalClose()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Dialog
        onClose={() => setOpenViewUserDialog(false)}
        open={openViewUserDialog}
      >
        <DialogContent>
          {selectedUser !== null && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h5>EMPLOYEE DETAILS</h5>
                    <p>Name: {selectedUser.name}</p>
                    <p>Date of Birth: {selectedUser.dateofBirth}</p>
                    <p>Contact No: {selectedUser.contactNo}</p>
                    <p>ParentsContactNo: {selectedUser.parentsContactNo}</p>
                    <p>PanCard No: {selectedUser.pan}</p>
                    <p>AadharCard No: {selectedUser.aadhar}</p>
                    <p>PermanentAddress: {selectedUser.address}</p>
                    <p>
                      Current Organisation: {selectedUser.previousExperience}
                    </p>
                    <p>Personal Emailid: {selectedUser.personalEmail}</p>
                    <p>Current Organisation: {selectedUser.currentOrg}</p>
                    <p>
                      Excepted Date of Joining: {selectedUser.expectedJoinDate}
                    </p>
                    <p>State: {selectedUser.state}</p>
                    <p>Pincode: {selectedUser.pincode}</p>
                    <p>Account No: {selectedUser.accountNumber}</p>
                    <p>Ifsc Code: {selectedUser.ifscCode}</p>
                    <p>Branch Name: {selectedUser.branchName}</p>
                    <p>Function: {selectedUser.func}</p>
                    <h5>Education Proof</h5>
                    <p>Latest Education: {selectedUser.latestEdu}</p>
                    <p>Passed out Year: {selectedUser.passedOut}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog> */}
      <Modal toggle={() => onViewUserModalClose()} isOpen={openViewUserDialog}>
        <ModalHeader toggle={() => onViewUserModalClose()}>
          {selectedUser !== null ? "Talent Acquisition Details" : ""}
        </ModalHeader>
        <ModalBody>
          {selectedUser !== null && (
            <Form>
              <div className="row">
                <div className="col-xl-12">
                  <h4 className="mt-0 header-title">EMPLOYEE DETAILS</h4>
                </div>
              </div>
              <div className="row  mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Name : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.name}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Date of Birth : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>
                        {new Date(selectedUser.dateofBirth).toLocaleDateString(
                          "en-GB"
                        )}
                      </h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Contact No : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.contactNo}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">ParentsContactNo : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.parentsContactNo}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">PanCard No : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.pan}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">AadharCard No : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.aadhar}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">PermanentAddress : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.address}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">
                        Current Organisation :
                      </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.previousExperience}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">
                        Excepted Date of Joining :
                      </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.EOJ}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Personal Email ID :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.personalEmail}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">State :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.state}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Pincode :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.pincode}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Account No :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.accountNumber}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Ifsc Code :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.ifscCode}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Branch Name : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.branchName}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12 d-flex">
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Function : </h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.func}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Latest Education :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.latestEdu}</h4>
                    </Label>
                  </FormGroup>
                  <FormGroup className="col-lg-4 d-flex">
                    <Label for="employeeName">
                      <h4 className="mt-0 header-title">Passed out Year :</h4>
                    </Label>
                    <Label for="employeeName" className="ml-3">
                      <h4>{selectedUser.passedOut}</h4>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
