/**
 * Agency Menu
 */
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { withRouter, Link } from "react-router-dom";

//Intl Meassages
import IntlMessages from "Util/IntlMessages";

import navLinks from "./NavLinks";
import NavMenuItem from "./NavMenuItem";
import Sidebar from "Components/Sidebar";

//Component
import SearchForm from "Components/Header/SearchForm";
import MobileSearchForm from "Components/Header/MobileSearchForm";

function AgencyMenu() {
  const [isMobileSearchFormVisible, setIsMobileSearchFormVisible] =
    useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  // mobile search form
  const openMobileSearchForm = () => {
    setIsMobileSearchFormVisible(true);
  };

  return (
    <div className="horizontal-menu agency-menu justify-content-between align-items-center">
      <ul className="list-unstyled nav">
        <li className="nav-item">
          <Link to={"/corporate/manager/dashboard"}>
            <i className="zmdi zmdi-view-dashboard"></i>
            <span className="menu-title">
              <IntlMessages id="Dashboard" />
            </span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link to={"/corporate/admin/newly"}>
            <i className="zmdi zmdi-widgets"></i>
            <span className="menu-title">
              <IntlMessages id="New Registration" />
            </span>
          </Link>
        </li> */}
        <li className="nav-item">
          <Link to={"/corporate/manager/onboard"}>
            <i className="zmdi zmdi-view-carousel"></i>
            <span className="menu-title">
              <IntlMessages id="OnBoarding" />
            </span>
          </Link>
        </li>
      </ul>
      {/* <IconButton
        className="hamburger-icon"
        color="inherit"
        aria-label="Menu"
        onClick={() => setMobileMenu(true)}
      >
        <MenuIcon />
      </IconButton>
      <div className="search-icon">
        <SearchForm />
        <IconButton
          mini="true"
          className="search-icon-btn"
          onClick={openMobileSearchForm}
        >
          <i className="zmdi zmdi-search"></i>
        </IconButton>
        <MobileSearchForm
          isOpen={isMobileSearchFormVisible}
          onClose={() => setIsMobileSearchFormVisible(false)}
        />
        <Drawer open={mobileMenu} onClose={() => setMobileMenu(false)}>
          <Sidebar agencySidebar />
        </Drawer>
      </div> */}
    </div>
  );
}
export default withRouter(AgencyMenu);
