/**
 * Data Table
 */
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Link } from "@material-ui/core";
import {
  apiPost,
  apiFormDatePost,
  setLoggedInuser,
} from "../../../Api/apiCommon";
// page title bar
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";

// rct card box
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";

// intl messages
import IntlMessages from "Util/IntlMessages";
import baseURL from "baseurl";

function OnboardindDataTable(props) {
  const [data, setData] = useState([]);
  const userString = sessionStorage.getItem("bixware_user");
  const User = JSON.parse(userString);
  console.log(User);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  /**
   * Open Add New User Modal
   */
  const opnAddNewUserModal = (e) => {
    e.preventDefault();
    setAddNewUserModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiPost("admin/getnewonboardingrlist", {
          roleUID: User.roleUID,
          userUID: User.userUID,
        });
        {
        }

        setData(response.data.empDetails);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    "boardingUID",
    "employeeName",
    "storeCode",
    "storeName",
    "gender",
    "email",
    "managerName",
  ];

  const options = {
    filterType: "dropdown",
  };
  return (
    <div className="data-table-wrapper">
      {/* <PageTitleBar
        title={<IntlMessages id="sidebar.dataTable" />}
        match={props.match}
      />
  */}

      <div className="d-flex justify-content-between py-20 px-10 border-bottom">
        <div>
          <a
            href="!#"
            // onClick={(e) => onReload(e)}
            className="btn-outline-default mr-10"
          >
            {/* <i className="ti-reload"></i> */}
          </a>
          <a
            href="!#"
            // onClick={(e) => e.preventDefault()}
            className="btn-outline-default mr-10"
          >
            {/* More */}
          </a>
        </div>
        <div>
          <a
            href="!#"
            onClick={(e) => opnAddNewUserModal(e)}
            color="primary"
            className="caret btn-sm mr-10"
          >
            Add New User <i className="zmdi zmdi-plus"></i>
          </a>
        </div>
      </div>
      <RctCollapsibleCard heading="List Of OnBoarding Employees" fullBlock>
        <MUIDataTable
          // title={"ONBOARDING"}
          data={data}
          columns={columns}
          options={options}
        />
      </RctCollapsibleCard>
    </div>
  );
}

export default OnboardindDataTable;
