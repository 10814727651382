/**
 * News App
 */
import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";

// Agency layout
import RctAgencyLayout from "Components/RctAgencyLayout";

// router service
import routerService from "Routes";
import corporateComponent from "Admin/CorporateComponent";
import DataTable from "Routes/tables/data-table";
import OnboardindDataTable from "Routes/tables/onboarding-table";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import Dashboard from "Routes/dashboard/ManagerDashboard";
import ManagerProfile from "Routes/users/Manager/index";

function RctNewsApp(props) {
  const { match, location } = props;
  // if (location.pathname === "/boxed") {
  //   return <Redirect to={"/boxed/dashboard/news"} />;
  // }
  return (
    <RctAgencyLayout enableBgImage>
      <Switch>
        <Route path="/corporate/manager/dashboard" component={Dashboard} />
        <Route path="/corporate/manager/onboard" component={ManagerProfile} />
      </Switch>
    </RctAgencyLayout>
  );
}

export default withRouter(RctNewsApp);
