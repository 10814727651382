/**
 * News App
 */
import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";

// Agency layout
import RctAgencyLayout from "Components/RctAgencyLayout";

// router service
import routerService from "Routes";
import corporateComponent from "Admin/CorporateComponent";
import DataTable from "Routes/tables/data-table";
import OnboardindDataTable from "Routes/tables/onboarding-table";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import NewRegProfile from "Routes/users/Newregistration";
import NewsDashboard from "Routes/dashboard/news";
import UserProfile from "Routes/users/user-management/index";
import Newregistration from "Routes/users/Newregistration/Newregistration";

function RctNewsApp(props) {
  const { match, location } = props;
  // if (location.pathname === "/boxed") {
  //   return <Redirect to={"/boxed/dashboard/news"} />;
  // }
  return (
    <RctAgencyLayout enableBgImage>
      <Switch>
        <Route path="/corporate/admin/dashboard" component={NewsDashboard} />
        <Route path="/corporate/admin/newly" component={NewRegProfile} />
        <Route path="/corporate/admin/onboard" component={UserProfile} />
        <Route
          path="/corporate/admin/newregistration"
          component={Newregistration}
        />
      </Switch>
    </RctAgencyLayout>
  );
}

export default withRouter(RctNewsApp);
