import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import RctBoxedLayout from "Container/RctBoxedLayout";
import RctBoxedLayoutManager from "Container/RctBoxedLayoutManager";

const ManagerComponent = () => {
  return (
    <>
      <>
        <RctBoxedLayoutManager />
      </>
    </>
  );
};

export default ManagerComponent;
